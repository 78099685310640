const IS_TOUCH = 'ontouchstart' in window;

const EVENTS = {
    POINTER_DOWN: IS_TOUCH ? 'touchstart' : 'mouseover',
    POINTER_OUT: IS_TOUCH ? 'touchleave' : 'mouseout',
};

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        //const [topCont, listCont, ctaCont] = el.querySelectorAll('div');
        const items = el.querySelectorAll('dl dd');
        const ham = el.querySelector('.hamburger');
        const switched = el.querySelectorAll('.switched');

        const resetAccordion = () => {
            Array.from(items).forEach(item => {
                item.classList.remove('is-active');
            });
        };

        const showItems = Array.from(items).map(item => e => {
            let status = false;
            if (item.classList.contains('is-active')) {
                status = true;
            }
            resetAccordion();
            if (!status) {
                item.classList.add('is-active');
            }
            e.preventDefault();
            e.stopPropagation();
        });

        const hiddenItems = Array.from(items).map(item => e => {
            if (item.classList.contains('is-active')) {
                resetAccordion();
            }
            e.preventDefault();
            e.stopPropagation();
        });

        const handleResize = () => {
            document.body.style.overflowY = 'visible';
        };

        const showMenu = e => {
            e.preventDefault();
            e.stopPropagation();
            if (ham.classList.toggle('is-active')) {
                document.body.style.overflowY = 'hidden';
            } else {
                document.body.style.overflowY = 'visible';
            }
            Array.from(switched).forEach(element => {
                element.classList.toggle('is-active');
            });
            
        };

        const goPage = e => {
            document.location = e.target.parentNode.href;
            e.preventDefault();
            e.stopPropagation();
        };

        Array.from(items).forEach((item, i) => {
            if (item.querySelectorAll('ul').length) {
                item.addEventListener(EVENTS.POINTER_DOWN, showItems[i]);
                item.addEventListener(EVENTS.POINTER_OUT, hiddenItems[i]);

                if (IS_TOUCH) {
                    Array.from(item.querySelectorAll('a')).forEach(a => {
                        a.querySelector('span').addEventListener(EVENTS.POINTER_DOWN, goPage);
                    });
                }
            }
        });

        if (ham) ham.addEventListener('click', showMenu);
        window.addEventListener('resize', handleResize);
    }
}
